@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}

/* Start Navbar Bottom Border Effect ====================================================== */
.link {
  text-decoration: none;
  position: relative;
  padding-bottom: 5px;
}

.link-nav-main {
  padding-bottom: 3px!important;
}

.link::after,
.active::after {
  content: '';
  position: absolute;
  width: 80%;
  height: 4px;
  background-color: #ff8432;
  bottom: 0;
  left: 10%;
  transform: scaleX(0);
  transform-origin: center;
  transition: .2s all ease-out;
}

.link:hover::after {
  transform: scaleX(1);
}

.active::after {
  transform: scaleX(1);
}
/* End Navbar Bottom Border Effect ====================================================== */
/* Remove browser x axis scrollbar */
html, body{
  overflow-x:hidden;
}
